'use strict';

/**
 * @ngdoc function
 * @name informaApp.directive:fileModel
 * @description
 * # fileModel
 * Directive of the informaApp
 */
angular.module('informaApp')
	.directive('fileModel', ['$parse', function ($parse) {
		return {
			restrict: 'A',
			link: function(scope, element, attrs) {
				var model = $parse(attrs.fileModel);
				var modelSetter = model.assign;

				element.bind('change', function() {
					scope.$apply(function() {
						modelSetter(scope, element[0].files[0]);
					});
				});
			}
		};
	}]);
